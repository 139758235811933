<template>
  <b-card>

    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="12"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.name)"
            :variant="`light-${resolveUserRoleVariant(userData.role.name)}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0 break-word">
                {{ userData.name }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <template v-if="$can('update', 'SchoolLicences')">
                <b-button
                  class="mr-1"
                  :to="{ 
                    name: 'students-edit', 
                    params: { 
                      id: school.id, 
                      student_id: userData.id, 
                    } 
                  }"
                  variant="primary"
                  v-if="!userData.deleted_at"
                >
                {{ $t('forms.edit') }}
                </b-button>
              </template>
              <template v-if="$can('delete', 'SchoolLicences')">
                <b-button
                  variant="outline-danger"
                  class="mr-1"
                  v-if="!userData.deleted_at"
                  @click="deleteModel"
                >
                {{ $t('forms.delete') }}
                </b-button>
              </template>
              <template v-if="$can('update', 'SchoolLicences')">
                <b-button
                  variant="outline-danger"
                  class="mr-1"
                  v-if="userData.deleted_at"
                  @click="recoverLicenceModel"
                >
                {{ $t('forms.recover') }}
                </b-button>
              </template>

              <b-button
                variant="outline-secondary"
                type="button"
                class="mr-1"
                @click="hasHistory() 
                  ? $router.go(-1) 
                  : $router.push({ 
                    name: 'school-students-list',
                    params: {
                      id: school.id,
                    } 
                  })"
              >
                {{ $t('forms.go_back') }}
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
      >
        <table class="mt-2 w-100">
          <tr>
            <th class="pb-50" style="width:200px;">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('fields.status') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.deleted_at ? $t('fields.inactive') : $t('fields.active') }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="SmileIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{$t('max_children')}}</span>
            </th>
            <td class="pb-50">
              {{ userData.max_children }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FlagIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('dash.cards.current_version') }}</span>
            </th>
            <td>
              {{ userData.library_version_id }}
            </td>
          </tr>
         <tr>
            <th class="pb-50">
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('fields.created_at') }}</span>
            </th>
            <td>
              {{ new Date(userData.created_at).toLocaleDateString() }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="EditIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('fields.updated_at') }}</span>
            </th>
            <td>
              {{ new Date(userData.updated_at).toLocaleDateString() }}
            </td>
          </tr>
          <tr v-if="userData.deleted_at">
            <th>
              <feather-icon
                icon="XCircleIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('fields.deleted_at') }}</span>
            </th>
            <td>
              {{ new Date(userData.deleted_at).toLocaleDateString() }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { recoverLicence, deleteLicence } from '@/api/routes'
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    school:{
      type: Object,
      required:true
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
  methods:{
    hasHistory () { 
       return window.history.length > 2 
    },
    recoverLicenceModel(){
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to recover this licence?', {
          title: 'Recover licence',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              'user_id': this.userData.id
            }
            this.$http.post(recoverLicence, data).then( response =>{
              // console.log(response)
              if(response.data.status == 200){
                this.$emit('refetch-data')

                  this.makeToast('success', this.$t('Success'), this.$t('forms.recover_success'));

              } else {
                // console.log(response.data, "NO SE PUEDE RECUPERAR");
                  this.makeToast('danger', this.$t('Error'), this.$t('forms.recover_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
            })
          }
        })
    },
    deleteModel(){
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this student?', {
          title: 'Delete student',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              'user_id': this.userData.id
            }
            this.$http.post(deleteLicence, data).then( response =>{
              // console.log(response)
              if(response.data.status == 200){
                this.$emit('refetch-data')

                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));

              } else {
                // console.log(response.data, "NO SE PUEDE ELIMINAR");
                this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
            })
          }
        })
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
  }
}
</script>

<style>

</style>
