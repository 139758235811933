var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-between flex-column",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-start"},[_c('b-avatar',{attrs:{"src":_vm.userData.avatar,"text":_vm.avatarText(_vm.userData.name),"variant":("light-" + (_vm.resolveUserRoleVariant(_vm.userData.role.name))),"size":"104px","rounded":""}}),_c('div',{staticClass:"d-flex flex-column ml-1"},[_c('div',{staticClass:"mb-1"},[_c('h4',{staticClass:"mb-0 break-word"},[_vm._v(" "+_vm._s(_vm.userData.name)+" ")]),_c('span',{staticClass:"card-text"},[_vm._v(_vm._s(_vm.userData.email))])]),_c('div',{staticClass:"d-flex flex-wrap"},[(_vm.$can('update', 'SchoolLicences'))?[(!_vm.userData.deleted_at)?_c('b-button',{staticClass:"mr-1",attrs:{"to":{ 
                  name: 'students-edit', 
                  params: { 
                    id: _vm.school.id, 
                    student_id: _vm.userData.id, 
                  } 
                },"variant":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('forms.edit'))+" ")]):_vm._e()]:_vm._e(),(_vm.$can('delete', 'SchoolLicences'))?[(!_vm.userData.deleted_at)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-danger"},on:{"click":_vm.deleteModel}},[_vm._v(" "+_vm._s(_vm.$t('forms.delete'))+" ")]):_vm._e()]:_vm._e(),(_vm.$can('update', 'SchoolLicences'))?[(_vm.userData.deleted_at)?_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-danger"},on:{"click":_vm.recoverLicenceModel}},[_vm._v(" "+_vm._s(_vm.$t('forms.recover'))+" ")]):_vm._e()]:_vm._e(),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"outline-secondary","type":"button"},on:{"click":function($event){_vm.hasHistory() 
                ? _vm.$router.go(-1) 
                : _vm.$router.push({ 
                  name: 'school-students-list',
                  params: {
                    id: _vm.school.id,
                  } 
                })}}},[_vm._v(" "+_vm._s(_vm.$t('forms.go_back'))+" ")])],2)])],1)]),_c('b-col',{attrs:{"cols":"12"}},[_c('table',{staticClass:"mt-2 w-100"},[_c('tr',[_c('th',{staticClass:"pb-50",staticStyle:{"width":"200px"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('fields.status')))])],1),_c('td',{staticClass:"pb-50 text-capitalize"},[_vm._v(" "+_vm._s(_vm.userData.deleted_at ? _vm.$t('fields.inactive') : _vm.$t('fields.active'))+" ")])]),_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"SmileIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('max_children')))])],1),_c('td',{staticClass:"pb-50"},[_vm._v(" "+_vm._s(_vm.userData.max_children)+" ")])]),_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"FlagIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('dash.cards.current_version')))])],1),_c('td',[_vm._v(" "+_vm._s(_vm.userData.library_version_id)+" ")])]),_c('tr',[_c('th',{staticClass:"pb-50"},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"CalendarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('fields.created_at')))])],1),_c('td',[_vm._v(" "+_vm._s(new Date(_vm.userData.created_at).toLocaleDateString())+" ")])]),_c('tr',[_c('th',[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('fields.updated_at')))])],1),_c('td',[_vm._v(" "+_vm._s(new Date(_vm.userData.updated_at).toLocaleDateString())+" ")])]),(_vm.userData.deleted_at)?_c('tr',[_c('th',[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"XCircleIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('fields.deleted_at')))])],1),_c('td',[_vm._v(" "+_vm._s(new Date(_vm.userData.deleted_at).toLocaleDateString())+" ")])]):_vm._e()])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }